import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Install from 'common/auth/Install';
import LazyLoadedImage from 'common/LazyLoadedImage';
import queryString from 'common/util/queryString';
import CannyGitHubIntegration from 'img/landing/canny-github-integration.webp';

import 'css/components/github/_GitHubInstall.scss';

export default class GitHubInstall extends Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        installation_id: PropTypes.string,
      }),
    }),
  };

  state = {
    installationID: this.props.location.query.installation_id,
  };

  render() {
    const { installationID } = this.state;
    const params = queryString.stringify({ installationID });
    const path = `/admin/settings/github${params}`;
    return (
      <div className="githubInstall">
        <LazyLoadedImage alt="Canny Github integration" src={CannyGitHubIntegration} />
        <div className="heading">Integrate Canny with GitHub</div>
        <Install path={path} />
      </div>
    );
  }
}
