import React, { Component } from 'react';

import Install from 'common/auth/Install';
import LazyLoadedImage from 'common/LazyLoadedImage';
import CannyHubspotIntegration from 'img/landing/canny-hubspot-integration.webp';

import 'css/components/hubspot/_HubspotInstall.scss';

export default class HubspotInstall extends Component {
  render() {
    const path = `/admin/settings/hubspot`;
    return (
      <div className="hubspotInstall">
        <LazyLoadedImage alt="Canny Hubspot integration" src={CannyHubspotIntegration} />
        <div className="heading">Integrate Canny with HubSpot</div>
        <Install path={path} />
      </div>
    );
  }
}
