import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import Install from 'common/auth/Install';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Spinner from 'common/Spinner';
import devURL from 'common/util/devURL';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';
import CannyTeamsIntegration from 'img/landing/canny-microsoft-teams-integration.webp';

import 'css/components/microsoftTeams/_MicrosoftTeamsInstall.scss';

export const RequiredNewChannelParams = [
  'azureTenantID',
  'channelID',
  'registeredByTeamUserID',
  'webhookURL',
  // TODO: Make these fields required again once
  // https://github.com/OfficeDev/microsoft-teams-library-js/issues/2160 is resolved
  // 'teamID',
  // 'teamName',
  // 'channelName',
  // 'groupID',
];
const SettingsURL = devURL('https://canny.io/install-teams');
const RegisterURL = devURL('https://canny.io/register');
const Step1 = 'Step1'; // iframe inside Microsoft Teams
const Step2 = 'Step2'; // Canny tab with query params from Microsoft Teams

class MicrosoftTeamsInstall extends Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    router: PropTypes.object,
    viewer: PropTypes.shape({
      companies: PropTypes.array,
    }),
  };

  state = {
    clickedContinue: false,
    config: null,
    isManaging: false,
    step: null,
    teamsLoaded: false,
    teamsContext: null,
    teamsSettings: null,
    userHasAccount: null,
  };

  componentDidMount() {
    this.setStep();
  }

  componentDidUpdate() {
    if (
      this.state.teamsLoaded === false &&
      this.state.teamsContext !== null &&
      this.state.teamsSettings !== null
    ) {
      this.setState({ teamsLoaded: true });
    }
  }

  setStep() {
    const { query } = this.props.location;
    const missingParams = RequiredNewChannelParams.filter((param) => !query[param]);

    // If there's no params, we must be in step 1.
    if (
      query.redirectToSettings !== 'true' &&
      missingParams.length === RequiredNewChannelParams.length
    ) {
      this.loadStep1();
      return;
    }

    this.loadStep2();
  }

  onStep1Loaded = async () => {
    const { query } = this.props.location;
    this.setState({ step: Step1 });

    const teamsContext = await this._microsoftTeams.app.getContext();
    const teamsSettings = await this._microsoftTeams.pages.getConfig();

    // Log context and settings to help with debugging
    console.log(JSON.stringify({ teamsContext, teamsSettings }, null, 2));

    this.setState({ teamsContext, teamsSettings });

    // They've already initially installed the connector and they are inside the
    // management settings iframe. This is from the connector's contentUrl.
    if (query.manage === 'true') {
      this.setState({ isManaging: true });
      return;
    }

    // Store some settings in the configuration of the connector
    await this._microsoftTeams.pages.config.setConfig({
      // Important when referencing your connector from somewhere else within Teams
      entityId: 'cannyNotificationsConfig',
      // This name will be shown to channel admins under "configured connectors"
      configName: 'Canny',
      contentUrl: `${SettingsURL}?manage=true`,
    });

    this._microsoftTeams.pages.config.registerOnSaveHandler((e) => {
      // You have to notify Teams that the saving process was successful, otherwise
      // the save operation will time out and display an error
      e.notifySuccess();
    });
  };

  async loadStep1() {
    this._microsoftTeams = await import('@microsoft/teams-js');
    await this._microsoftTeams.app.initialize();
    await this.onStep1Loaded();
  }

  loadStep2() {
    const { location, router } = this.props;

    this.setState({
      step: Step2,
      config: {
        ...location.query,
      },
    });

    // Remove query from the url
    router.replace({ pathname: location.pathname });
  }

  onContinue = () => {
    const { teamsContext, teamsSettings } = this.state;
    const query = queryString.stringify({
      azureTenantID: teamsContext.user.tenant.id,
      channelID: teamsContext.channel.id,
      channelName: teamsContext.channel.displayName,
      groupID: teamsContext.team?.groupId,
      registeredByTeamUserID: teamsContext.user.id,
      teamID: teamsContext.team?.internalId,
      teamName: teamsContext.team?.displayName,
      webhookURL: teamsSettings.webhookUrl,
    });

    this.setState({ clickedContinue: true });

    // Enable the integration's save button so the user can finish installing it.
    this._microsoftTeams.pages.config.setValidityState(true);

    try {
      window.open(`${SettingsURL}${query}`);
    } catch (error) {
      // The Teams Electron client tries to communicate with the new tab and throws an error.
    }
  };

  onManage = () => {
    const query = queryString.stringify({
      redirectToSettings: true,
    });
    // Redirect to their Canny admin settings page
    window.open(`${SettingsURL}${query}`);
  };

  onUserHasAccount = (hasAccount) => {
    this.setState({
      userHasAccount: hasAccount,
    });
  };

  renderStep() {
    const { step } = this.state;
    if (step === Step1) {
      return this.renderStep1();
    }
    if (step === Step2) {
      return this.renderStep2();
    }
    return <Spinner />;
  }

  renderStep1() {
    const { clickedContinue, isManaging, teamsLoaded } = this.state;

    const notificationOptions =
      'Your notification options include new posts, new votes, new comments, vote milestones, status changes, and new changelog entries.';

    if (isManaging) {
      return (
        <>
          <div className="text">
            To configure notification preferences, please head over to your Canny admin settings.
          </div>
          <div className="continueButton">
            <Button onTap={this.onManage} value="Manage in Canny" />
          </div>
          <div className="bodyText">{notificationOptions}</div>
        </>
      );
    }

    if (clickedContinue) {
      return (
        <div className="text">
          After you complete the configuration on Canny's side, please click the Save button below.
        </div>
      );
    }

    return (
      <>
        <div className="text">
          You can create or manage the configuration within Canny's settings. Click the button below
          to continue.
        </div>
        <div className="continueButton">
          <Button disabled={!teamsLoaded} onTap={this.onContinue} value="Continue in Canny" />
        </div>
        <div className="bodyText">
          If you're new to Canny, you can get started{' '}
          <a className="link" href={RegisterURL} rel="noopener" target="_blank">
            here
          </a>
          .
        </div>
        <div className="bodyText">{notificationOptions}</div>
      </>
    );
  }

  renderStep2() {
    const { viewer } = this.props;
    const { config, userHasAccount } = this.state;
    const query = queryString.stringify(config);
    const path = `/admin/settings/microsoft-teams${query}`;

    if (userHasAccount === true || (viewer && viewer.companies && viewer.companies.length > 0)) {
      return <Install path={path} />;
    }

    if (userHasAccount === false) {
      const link = (
        <a className="link" href={RegisterURL} rel="noopener" target="_blank">
          registration
        </a>
      );
      return (
        <div className="text">
          To use Canny with Microsoft Teams, you'll need an account. To get started, head to our{' '}
          {link} page.
        </div>
      );
    }

    return (
      <div>
        <div className="text">Do you have an account with Canny?</div>
        <div className="buttons">
          <Button onTap={() => this.onUserHasAccount(true)} value="Yes" />
          <Button onTap={() => this.onUserHasAccount(false)} value="No" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="microsoftTeamsInstall">
        <div className="center">
          <LazyLoadedImage
            className="logos"
            src={CannyTeamsIntegration}
            alt="Canny and Microsoft Teams logos"
          />
          <div className="heading">Canny Notifications for Microsoft Teams</div>
          {this.renderStep()}
        </div>
      </div>
    );
  }
}

export default compose(
  withContexts({
    location: LocationContext,
    router: RouterContext,
    viewer: ViewerContext,
  })
)(MicrosoftTeamsInstall);
