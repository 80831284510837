import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import ContentContainer from 'common/containers/ContentContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import ResetPasswordHelmet from 'common/helmets/ResetPasswordHelmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Strings from 'common/Strings';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/_ResetPassword.scss';

class ResetPassword extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    error: null,
    success: false,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.passwordRef = React.createRef();
    this.confirmRef = React.createRef();
  }

  componentDidMount() {
    this._email = this.props.location.query.email;
    this._resetPasswordID = this.props.location.query.id;
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const password = this.passwordRef.current.getValue();
    const confirm = this.confirmRef.current.getValue();

    if (!password || !validateInput.password(password)) {
      this.setState({
        error: Strings.invalidPassword,
      });
      return;
    } else if (password !== confirm) {
      this.setState({
        error: Strings.passwordConfirmMismatch,
      });
      return;
    }

    AJAX.post(
      '/api/viewer/resetPassword',
      {
        email: this._email,
        password: password,
        resetPasswordID: this._resetPasswordID,
      },
      this.onResetPasswordResponse
    );

    this.setState({
      error: null,
      submitting: true,
    });
  };

  onResetPasswordResponse = (response) => {
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'slow down':
          'You are trying to reset your password too much. Please wait a few minutes before trying again.',
        'password is too weak': 'Please enter a stronger password.',
      },
    });

    if (!error) {
      this.setState({
        submitting: false,
        success: true,
      });
      return;
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }
    return <div className="error">{this.state.error}</div>;
  }

  render() {
    if (this.state.success) {
      return (
        <ContentContainer
          outerClassName="resetPassword"
          innerClassName="resetPasswordContents card">
          <ResetPasswordHelmet success={true} />
          <div className="message">
            Your password has successfully been reset, you may now log in with your
            new&nbsp;password.
          </div>
        </ContentContainer>
      );
    }

    return (
      <ContentContainer outerClassName="resetPassword" innerClassName="resetPasswordContents card">
        <ResetPasswordHelmet success={false} />
        <h1>Reset your password</h1>
        <form onSubmit={this.onSubmit}>
          <TextInput inset="Email" disabled value={this.props.location.query.email} />
          <TextInput type="password" inset="New password" ref={this.passwordRef} />
          <TextInput type="password" inset="Confirm password" ref={this.confirmRef} />
          {this.renderError()}
          <div className="buttonContainer">
            <Button formButton={true} loading={this.state.submitting} value="Reset Password" />
          </div>
        </form>
      </ContentContainer>
    );
  }
}

export default compose(withContexts({ location: LocationContext }))(ResetPassword);
