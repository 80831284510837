import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import LoginForm from 'common/auth/LoginForm';
import UserSignupForm from 'common/auth/UserSignupForm';
import ContentContainer from 'common/containers/ContentContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Tappable from 'common/Tappable';
import devURL from 'common/util/devURL';
import getCookies from 'common/util/getCookies';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/www/_LandingAcceptInvite.scss';

class LandingAcceptInvite extends Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        companyName: PropTypes.string,
        inviteID: PropTypes.string,
        subdomain: PropTypes.string,
      }),
    }),
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    loginMode: false,
  };

  onAuthSuccess = () => {
    this.addAndRedirectUser(true);
  };

  onToggleLoginMode = () => {
    this.setState({
      loginMode: !this.state.loginMode,
    });
  };

  componentDidMount() {
    const {
      location: { query },
      viewer,
    } = this.props;

    // Case #1: viewer is already a member, redirect
    const viewerIsMember =
      viewer &&
      viewer.companies &&
      viewer.companies.some((company) => {
        company.subdomain === query.subdomain;
      });
    if (viewerIsMember) {
      window.location = devURL(`https://${query.subdomain}.canny.io/admin`);
      return;
    }

    if (!query.inviteID || !query.companyName || !query.subdomain) {
      window.location = devURL(`https://canny.io`);
      return;
    }

    // Case #2: logged in but with different email, show error
    if (query.email && viewer && !viewer.loggedOut && viewer.email !== query.email) {
      this.setState({
        error: `You are accepting an invite for a different email. Please log in with the correct email (${query.email}).`,
      });
      return;
    }

    // Case #3: not member but logged in, add them
    if (viewer._id) {
      this.addAndRedirectUser(false);
      return;
    }

    // Case #4: not logged in, show sign up / log in form
  }

  async addAndRedirectUser(needsRefresh) {
    const {
      location: { query },
    } = this.props;
    const [hubspotutk] = getCookies(['hubspotutk']);
    const response = await AJAX.post('/api/company/joinTeam', {
      hubspotutk,
      inviteID: query.inviteID,
      subdomain: query.subdomain,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: "Your inviteID didn't work, are you sure you copied the right link?",
        'plan does not support': 'Your team cannot accept members for this role',
        'could not find role': 'The role you are trying to accept does not exist',
        'invite expired': 'Your inviteID expired, please check your latest emails!',
        'email mismatch': `You are accepting an invite for a different email. Log in with the correct email (${query.email}).`,
        'admin limit':
          'Your team has reached the admin limit. Please contact support to increase your limit or invite users as contributors only.',
      },
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    window.location = devURL(`https://${query.subdomain}.canny.io/admin`);
    return;
  }

  renderAuthForm() {
    const {
      location: { query },
      viewer,
    } = this.props;
    if (viewer && viewer._id) {
      return null;
    }

    const title = `Join ${query.companyName} on Canny`;

    if (this.state.loginMode) {
      return (
        <div className="authForm card">
          <div className="topContainer">
            <h1>{title}</h1>
            <LoginForm
              acceptedEmail={query.email}
              onSuccess={this.onAuthSuccess}
              queryAllowlist={{ inviteID: true }}
            />
          </div>
          <Tappable onTap={this.onToggleLoginMode}>
            <div className="toggleButton">
              Need an account? <div className="emphasize">Sign up</div>
            </div>
          </Tappable>
        </div>
      );
    } else {
      return (
        <div className="authForm card">
          <div className="topContainer">
            <h1>{title}</h1>
            <UserSignupForm
              acceptedEmail={query.email}
              onSuccess={this.onAuthSuccess}
              queryAllowlist={{ inviteID: true }}
              signupCTA="Join"
            />
          </div>
          <Tappable onTap={this.onToggleLoginMode}>
            <div className="toggleButton">
              Already have an account? <div className="emphasize">Log In</div>
            </div>
          </Tappable>
        </div>
      );
    }
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="card error">{this.state.error}</div>;
  }

  render() {
    return (
      <ContentContainer innerClassName="landingAcceptInvite">
        {this.renderErrorMessage()}
        {this.renderAuthForm()}
      </ContentContainer>
    );
  }
}

export default withContexts({
  viewer: ViewerContext,
})(LandingAcceptInvite);
