import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadViewer } from 'common/actions/viewer';
import AJAX from 'common/AJAX';
import LoginForm from 'common/auth/LoginForm';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import SignInHelmet from 'common/helmets/SignInHelmet';
import Link from 'common/Link';
import addQueryParamToHref from 'common/util/addQueryParamToHref';
import devURL from 'common/util/devURL';
import isExpectedURLOrigin from 'common/util/isExpectedURLOrigin';
import withContexts from 'common/util/withContexts';

import 'css/components/_SignIn.scss';

class SignIn extends Component {
  static propTypes = {
    location: PropTypes.object,
    router: PropTypes.object.isRequired,
    viewer: PropTypes.object,
  };

  componentWillMount() {
    const { router, viewer } = this.props;
    if (viewer && !viewer.loggedOut) {
      if (viewer.companies && viewer.companies.length === 0) {
        router.replace('/register');
      }
    }
  }

  componentDidMount() {
    const { viewer } = this.props;
    if (viewer && !viewer.loggedOut) {
      if (viewer.companies && viewer.companies.length > 0) {
        this.redirectUser(viewer);
      }
    }
  }

  redirectUser = async (userWithCompanies) => {
    const {
      location: { query },
    } = this.props;
    const redirect = query.redirect && decodeURIComponent(query.redirect);

    // 1. handle docs redirect
    const docsOrigin = devURL('https://developers.canny.io');
    if (redirect && redirect.startsWith(docsOrigin) && isExpectedURLOrigin(redirect, docsOrigin)) {
      window.location.assign(redirect);
      return;
    }

    // 2. handle canny subdomain redirect
    if (redirect && redirect.match(/^https:\/\/[a-z0-9-]+\.canny\.io/)) {
      const viewerAdminCompany = userWithCompanies.companies.find((company) => {
        const cannyDomainOrigin = devURL(`https://${company.subdomain}.canny.io`);
        return isExpectedURLOrigin(redirect, cannyDomainOrigin);
      });
      // if the user is an admin, we need to send them to their auth integration, if they have one
      const redirectURL = viewerAdminCompany?.integrationAuthRedirectionURL ?? redirect;
      window.location.assign(redirectURL);
      return;
    }

    // 3. handle custom domain redirect (with auth token)
    if (redirect && redirect.match(/^https:\/\//)) {
      const viewerAdminCompany = userWithCompanies.companies.find((company) => {
        return company.domains.some((domain) => {
          const customDomainOrigin = `https://${domain}`;
          return isExpectedURLOrigin(redirect, customDomainOrigin);
        });
      });
      if (viewerAdminCompany && viewerAdminCompany.integrationAuthRedirectionURL) {
        window.location.assign(viewerAdminCompany.integrationAuthRedirectionURL);
        return;
      }

      try {
        const domain = new URL(redirect).host;
        const verifyResponseJSON = await AJAX.post('/api/domains/verify', { domain });
        const verifyResponse = JSON.parse(verifyResponseJSON);
        if (verifyResponse.success && verifyResponse.companyID) {
          if (!viewerAdminCompany || viewerAdminCompany._id === verifyResponse.companyID) {
            const tokenResponseJSON = await AJAX.post('/api/viewer/getAuthToken', {
              companyID: verifyResponse.companyID,
              domain,
            });
            const tokenResponse = JSON.parse(tokenResponseJSON);
            if (tokenResponse && tokenResponse.authToken) {
              const token = tokenResponse.authToken;
              const redirectURL = addQueryParamToHref(redirect, 'authToken', token);
              window.location.assign(redirectURL);
              return;
            }
          }
        }
      } catch (error) {
        // no-op
      }
    }

    // 4. fallback to first/easiest company to auth into
    const company =
      userWithCompanies.companies.find((c) => !c.integrationAuthRedirectionURL) ??
      userWithCompanies.companies[0];
    if (company.integrationAuthRedirectionURL) {
      window.location.assign(company.integrationAuthRedirectionURL);
      return;
    }

    // handle path redirects (eg. /login?redirect=/admin/settings/billing)
    const companyOrigin = devURL(`https://${company.subdomain}.canny.io`);
    if (redirect && redirect.startsWith('/') && !redirect.startsWith('/api/')) {
      const url = `${companyOrigin}${redirect}`;
      if (isExpectedURLOrigin(url, companyOrigin)) {
        window.location.assign(url);
        return;
      }
    }

    // fallback to simply authing into the admin view
    window.location.assign(`${companyOrigin}/admin`);
  };

  onSuccess = (user) => {
    const { companies } = user;

    if (!companies.length) {
      this.props.reloadViewer().then(() => {
        const { router } = this.props;
        router.push('/register');
      });
      return;
    }

    this.redirectUser(user);
  };

  render() {
    return (
      <div className="signIn">
        <SignInHelmet />
        <div className="signInContainer">
          <h1>Log in to your account</h1>
          <LoginForm onSuccess={this.onSuccess} />
        </div>
        <Link className="signUpLink" to="/register">
          Don't have an account? Sign up
        </Link>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadViewer: () => {
      return Promise.all([dispatch(reloadViewer())]);
    },
  })),
  withContexts(
    {
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(SignIn);
