import React, { Component } from 'react';

import Install from 'common/auth/Install';
import LazyLoadedImage from 'common/LazyLoadedImage';

import 'css/components/clickup/_ClickupInstall.scss';

import CannyClickupIntegration from 'img/canny-clickup-integration.png';

export default class ClickupInstall extends Component {
  render() {
    const path = `/admin/settings/clickup`;
    return (
      <div className="clickupInstall">
        <LazyLoadedImage alt="Canny Clickup integration" src={CannyClickupIntegration} />
        <div className="heading">Integrate Canny with ClickUp</div>
        <Install path={path} />
      </div>
    );
  }
}
