import React, { Component } from 'react';

import Install from 'common/auth/Install';
import LazyLoadedImage from 'common/LazyLoadedImage';

import 'css/components/intercom/_IntercomInstall.scss';

import CannyIntercomIntegration from 'img/canny-intercom-integration.png';

export default class IntercomInstall extends Component {
  render() {
    return (
      <div className="intercomInstall">
        <LazyLoadedImage alt="Canny Intercom integration" src={CannyIntercomIntegration} />
        <div className="heading">Integrate Canny with Intercom</div>
        <Install path={'/admin/settings/intercom?connect=true'} />
      </div>
    );
  }
}
