import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Helmet from './Helmet';

export default class ResetPasswordHelmet extends Component {
  static propTypes = {
    success: PropTypes.bool.isRequired,
  };

  render() {
    var title = this.props.success ? 'Password Reset' : 'Reset Password';
    title += ' | Canny';

    return <Helmet meta={[{ name: 'referrer', content: 'no-referrer' }]} title={title} />;
  }
}
