export default function isExpectedURLOrigin(url: string, expectedOrigin: string) {
  try {
    const parsedURL = new URL(url);

    // If the url's parsed origin doesn't actually match our expected origin,
    // then someone is attempting to trick the redirect into going to another website.
    return parsedURL.origin === expectedOrigin;
  } catch (error) {
    // invalid URL
    return false;
  }
}
