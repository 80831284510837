import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as AuthRequests from 'common/auth/AuthRequests';
import AuthButton from 'common/AuthButton';
import ReCAPTCHAWidget from 'common/common/ReCAPTCHAWidget';
import EmailButton from 'common/EmailButton';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import validateInput from 'common/validateInput';

import 'css/components/auth/_UserSignupForm.scss';

export default class UserSignupForm extends Component {
  static propTypes = {
    acceptedEmail: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    queryAllowlist: PropTypes.object,
    signupCTA: PropTypes.string,
  };

  static defaultProps = {
    queryAllowlist: {},
    signupCTA: 'Sign Up',
  };

  state = {
    error: null,
    formMode: false,
    oauthData: null,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.captchaWidgetRef = React.createRef();
    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  onAuthFormSubmit = async (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const { acceptedEmail } = this.props;
    const name = this.nameRef.current.getValue();
    const email = this.emailRef.current.getValue();
    const password = this.passwordRef.current && this.passwordRef.current.getValue();
    const { oauthData } = this.state;

    var error = null;
    if (!validateInput.userName(name)) {
      error = 'Please enter a valid name (2-50)';
    } else if (!validateInput.email(email)) {
      error = 'Please enter a valid email';
    } else if (!oauthData && !validateInput.password(password)) {
      error = 'Please enter a valid password (6-200)';
    } else if (acceptedEmail && email !== acceptedEmail) {
      error = `You are signing up with a different email than the invite. Please log in with the correct email (${acceptedEmail}).`;
    }

    this.setState({ error });
    if (error) {
      return;
    }

    const requestData = {
      ...oauthData,
      ...(!oauthData && { captcha: await this.captchaWidgetRef?.current.getCAPTCHA() }),
      ...(password && { password }),
      email,
      name,
    };

    this.setState({
      submitting: true,
    });

    const { error: authError, redirecting } = await AuthRequests.signup(requestData);

    if (redirecting) {
      return;
    }

    if (!authError) {
      this.setState(
        {
          submitting: false,
        },
        () => this.props.onSuccess()
      );
      return;
    }

    if (authError.type === 'invalid captcha') {
      this.captchaWidgetRef?.current.refresh();
    }

    this.setState({
      submitting: false,
      error: authError.message,
    });
  };

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onOAuthSuccess = async (oauthData) => {
    const { acceptedEmail } = this.props;
    if (acceptedEmail && oauthData.email !== acceptedEmail) {
      this.setState({
        error: `You are signing up with a different email than the invite. Please log in with the correct email (${acceptedEmail}).`,
      });
      return;
    }

    const { parsedResponse, redirecting } = await AuthRequests.login(oauthData);

    if (redirecting) {
      return;
    }

    if (parsedResponse?.user) {
      this.props.onSuccess(parsedResponse.user);
      return;
    }

    this.setState({
      formMode: true,
      oauthData: oauthData,
    });
  };

  onToggleFormMode = () => {
    this.setState({
      error: null,
      formMode: !this.state.formMode,
    });
  };

  renderAuthButtons() {
    if (this.state.formMode) {
      return null;
    }

    return (
      <div className="authButtons">
        {this.renderErrorMessage()}
        <AuthButton
          authType="google"
          onFailure={this.onOAuthFailure}
          onSuccess={this.onOAuthSuccess}
          queryAllowlist={this.props.queryAllowlist}
          value="Sign up with Google"
        />
        <AuthButton
          authType="facebook"
          onFailure={this.onOAuthFailure}
          onSuccess={this.onOAuthSuccess}
          queryAllowlist={this.props.queryAllowlist}
          value="Sign up with Facebook"
        />
        <AuthButton
          authType="github"
          onFailure={this.onOAuthFailure}
          onSuccess={this.onOAuthSuccess}
          queryAllowlist={this.props.queryAllowlist}
          value="Sign up with GitHub"
        />
        <EmailButton onTap={this.onToggleFormMode} value="Sign up with Email" />
      </div>
    );
  }

  renderAvatar() {
    const { oauthData } = this.state;
    if (!oauthData || !oauthData.avatarURL) {
      return null;
    }

    return <LazyLoadedImage alt="avatar" className="avatar" src={oauthData.avatarURL} />;
  }

  renderForm() {
    const { acceptedEmail } = this.props;
    const { formMode, oauthData } = this.state;
    if (!formMode) {
      return null;
    }

    return (
      <form onSubmit={this.onAuthFormSubmit}>
        {this.renderAvatar()}
        <TextInput
          ref={this.nameRef}
          defaultValue={oauthData && oauthData.name}
          inset="Name"
          placeholder="Jane Doe"
        />
        <TextInput
          ref={this.emailRef}
          defaultValue={oauthData && oauthData.email}
          inset="Email"
          placeholder="Email"
          {...(acceptedEmail && !oauthData && { value: acceptedEmail, disabled: true })}
        />
        {oauthData ? null : (
          <TextInput
            inset="Password"
            placeholder="Password"
            ref={this.passwordRef}
            type="password"
          />
        )}
        {this.renderErrorMessage()}
        <ReCAPTCHAWidget ref={this.captchaWidgetRef} />
        <Button
          buttonType="cannyButton"
          className="submitButton"
          formButton={true}
          loading={this.state.submitting}
          value={this.props.signupCTA}
        />
      </form>
    );
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    return (
      <div className="userSignupForm">
        {this.renderAuthButtons()}
        {this.renderForm()}
      </div>
    );
  }
}
